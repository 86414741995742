gsap.registerPlugin(ScrollTrigger);

const images = gsap.utils.toArray('img');
const loader = document.querySelector('.loader--text');
const updateProgress = (instance) => 
  loader.textContent = `${Math.round(instance.progressedCount * 100 / images.length)}%`;

const showDemo = () => {
  document.body.style.overflow = 'auto';
  document.scrollingElement.scrollTo(0, 0);
  gsap.to(document.querySelector('.loader'), { autoAlpha: 0 });
  
  gsap.utils.toArray('.paginaheader:not(.is-style-panorama):not(.is-style-slider)').forEach((section, index) => {
    const t = document.querySelector('#paginaheader-trigger');
    const w = section.querySelector('.images-wrapper');
    const h1 = section.querySelector('h1');
    const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];
    gsap.fromTo(w, {  x  }, {
      x: window.outerWidth / 3,
      // x: document.querySelector('#main').clientWidth / 2,
      ease: "Expo.easeOut",
      scrollTrigger: { 
        trigger: t,
        scrub: 0.5,
        //markers: true,
      }
    });

    // gsap.fromTo(h1, {  x  }, {
    //   x: window.outerWidth / 6,
    //   scrollTrigger: { 
    //     trigger: t,
    //     scrub: 0.5,
    //   }
    // });
  });

  gsap.utils.toArray('.paginaheader.is-style-slider').forEach((container, index) => {
    const t = document.querySelector('#paginaheader-trigger');

    gsap.to(container, {
      x: () => -(container.scrollWidth - document.documentElement.clientWidth) + "px",
      ease: "none",
      scrollTrigger: {
        trigger: t,
        invalidateOnRefresh: true,
        pin: true,
        scrub: 1,
        end: () => "+=" + container.offsetWidth
      }
    })
  });
}

document.addEventListener('DOMContentLoaded', showDemo);
