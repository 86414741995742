document.addEventListener("DOMContentLoaded", function() {

    [].slice.call( document.querySelectorAll( 'a[href*="-modal"]' ) ).forEach( anchor => {
        console.log(anchor.hash.substring(1));

        anchor.classList.add( `open-${anchor.hash.substring(1)}` );

        // if ( document.querySelector( '[data-selector="open-' + anchor.hash.substring(1) + '"]' ) ) {

        // }
    })
});

// document.addEventListener("DOMContentLoaded", function() {

//     const onScrolledInView = () => {

//     };

//     let observer = new IntersectionObserver(onScrolledInView, {});

//     [].slice.call( document.querySelectorAll( '.scroll-trigger-popup' ) ).forEach( el => observer.observe(el) );

// });
