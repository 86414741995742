document.addEventListener("DOMContentLoaded", function() {

    [].forEach.call(document.querySelectorAll('[data-toggle]'), function (button, index) {
        const target = document.getElementById( button.dataset.toggle );

        if ( ! target ) {
            return;
        }

        button.addEventListener('click', function (e) {
            target.classList.toggle('is-open');

            if ( target.classList.contains('is-open') ) {
                button.setAttribute('aria-expanded', true);
                document.body.classList.add( 'is-menu-open' );
            } else {
                button.removeAttribute('aria-expanded');
                document.body.classList.remove( 'is-menu-open' );
            }
        });
    });


    // Submenu collapse on mobile

    [].forEach.call(document.querySelectorAll('#main-nav li.menu-item-object-custom > a'), function (anchor, index) {
        anchor.addEventListener('click', function (e) {
            const nav = document.querySelector('#main-nav');
            // e.preventDefault();

            nav.removeAttribute('aria-expanded');
            nav.classList.remove('is-open');
            document.body.classList.remove('is-menu-open');
        });
    });


    // Close navigation on current-menu-item click
    [].forEach.call(document.querySelectorAll('#main-nav li.current-menu-item a'), function (anchor, index) {
        anchor.addEventListener('click', function (e) {

            if ( ! parent.hasAttribute('aria-expanded') ) {
                parent.setAttribute('aria-expanded', true);
            } else {
                parent.removeAttribute('aria-expanded');
            }
        });
    });

});
