// [data-src]
import Cookies from 'js-cookie';
import jQuery from 'jquery';

const $ = jQuery.noConflict();

function onContentLoaded() {
	const popupTriggers = [].slice.call(document.querySelectorAll('.popup-trigger'));

	if ("IntersectionObserver" in window) {
		let observer = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
                    const id = entry.target.dataset.id;
                    const delay = entry.target.dataset.delay || 0;
                    const popupEl = document.getElementById(id || null);

                    observer.unobserve(entry.target);

                    if ( popupEl ) {
                        const cookieName = `popupSeen-${entry.target.dataset.cookieName}` || 'popupSeen';
                
                        if ( Cookies.get(cookieName) == undefined ) {
                            setTimeout( function() {
                                popupEl.setAttribute('aria-hidden', false);
                            }, delay );

                            const closeButton = popupEl.querySelector('[data-close]');
                            const bgEl = popupEl.querySelector('.popup__bg');

                            if ( bgEl ) {
                                bgEl.addEventListener('click', () => {
                                    popupEl.setAttribute('aria-hidden', true);
                                    Cookies.set(cookieName, '1', { expires: 365 });
                                })
                            }

                            if ( closeButton ) {
                                closeButton.addEventListener('click', () => {
                                    popupEl.setAttribute('aria-hidden', true);
                                    Cookies.set(cookieName, '1', { expires: 365 });
                                })
                            }
                        }
                
                    }
                    
                }
			});
		});

		popupTriggers.forEach(function(el) {
			observer.observe(el);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}

    // Popup on click

    const popups = [].slice.call(document.querySelectorAll('.popup'));

    popups.forEach(popupEl => {
        const id = popupEl.getAttribute('id');
        [].slice.call(document.querySelectorAll(`[href*="#${id}"]`)).forEach(anchor => {
            anchor.addEventListener('click', (e) => {
                e.preventDefault();
                popupEl.setAttribute('aria-hidden', false);

                const closeButton = popupEl.querySelector('[data-close]');
                const bgEl = popupEl.querySelector('.popup__bg');

                if ( bgEl ) {
                    bgEl.addEventListener('click', () => {
                        popupEl.setAttribute('aria-hidden', true);
                        // Cookies.set(cookieName, '1', { expires: 365 });
                    })
                }

                if ( closeButton ) {
                    closeButton.addEventListener('click', () => {
                        popupEl.setAttribute('aria-hidden', true);
                        // Cookies.set(cookieName, '1', { expires: 365 });
                    })
                }
            })
        });
    });
};

document.addEventListener("DOMContentLoaded", onContentLoaded);