// require('swiper/swiper-bundle.min.js');

// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Scrollbar, Controller, Thumbs, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Scrollbar, Controller, Thumbs, Autoplay]);

window.swipers = [];

document.addEventListener("DOMContentLoaded", function() {

	const swipers = document.querySelectorAll( '.swiper-init' );

	swipers.forEach((el) => {

		let params = {};

		if ( 'swiper' in el.dataset ) {
			params = JSON.parse( el.dataset.swiper );
		}

		const swiper = new Swiper(el, params);
		window.swipers.push( swiper );

		const event = new Event('swiperInit');
		document.dispatchEvent(event);

	});

});


// // Ervaringen slider

// document.addEventListener("DOMContentLoaded", function() {

// 	if ( ! document.querySelectorAll('.ervaringen').length ) {
// 		return;
// 	}

// 	const quotesSwiperEl = document.querySelector( '.ervaringen__quotes .swiper-container' );
// 	const logosSwiperEl = document.querySelector( '.ervaringen__logos .swiper-container' );

// 	const quotesSliderParams = quotesSwiperEl.dataset.swiper ? JSON.parse(quotesSwiperEl.dataset.swiper) : {};
// 	const logosSliderParams = logosSwiperEl.dataset.swiper ? JSON.parse(logosSwiperEl.dataset.swiper) : {};

// 	quotesSliderParams.thumbs = {
// 		swiper: logosSliderParams
// 	};

// 	const quotesSlider = new Swiper(quotesSwiperEl, quotesSliderParams);
// 	// const logosSlider = new Swiper(logosSwiperEl, logosSliderParams);

// 	// console.log(quotesSlider);

// 	// quotesSlider.swiper.controller.control = logosSlider;
// 	// logosSlider.swiper.controller.control = quotesSlider;

// });
